/** @jsx jsx */
import React from "react"
import { Text, Box, jsx } from "theme-ui"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import SEO from "../components/seo"

export default function Blog({ data }) {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Box my={[4, 6, 6]}>
        <ContentSection
          heading="Privacy Policy"
          body={
            <Box
              sx={{ maxWidth: "800px", margin: "auto", textAlign: "justify" }}
            >
              <Text variant="secondary" sx={{ fontSize: 3 }}>
                We view protection of Your privacy as a very important
                principle. We understand clearly that You and Your Personal
                Information is one of Our most important assets. We store and
                process Your Information including any sensitive financial
                information collected (as defined under the Information
                Technology Act, 2000), if any, on computers that may be
                protected by physical as well as reasonable technological
                security measures and procedures in accordance with Information
                Technology Act 2000 and Rules there under. Our current Privacy
                Policy is available at Privacy. Our current Privacy Policy is
                available at Privacy. You object to Your Information being
                transferred or used in this way please do not use Platform. If
                You object to Your Information being transferred or used in this
                way please do not use Platform.
              </Text>
              <Text mt={4} variant="secondary" sx={{ fontSize: 3 }}>
                We may share personal information with our other corporate
                entities and affiliates. These entities and affiliates may
                market to you as a result of such sharing unless you explicitly
                opt-out.
              </Text>
              <Text mt={4} variant="secondary" sx={{ fontSize: 3 }}>
                We may disclose personal information to third parties. This
                disclosure may be required for us to provide you access to our
                Services, to comply with our legal obligations, to enforce our
                User Agreement, to facilitate our marketing and advertising
                activities, or to prevent, detect, mitigate, and investigate
                fraudulent or illegal activities related to our Services. We do
                not disclose your personal information to third parties for
                their marketing and advertising purposes without your explicit
                consent.
              </Text>
            </Box>
          }
        />
      </Box>
    </Layout>
  )
}
